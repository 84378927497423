body {
  margin: 0;
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
#root {
  background-color: #efefef;
  height: 100%;
}

div,
p,
span,
a,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
table,
tr,
td,
th,
tbody,
thead,
tfoot,
img,
input,
textarea,
button {
  font-family: "Source Sans Pro", sans-serif;
}

thead.MuiTableHead-root.css-15wwp11-MuiTableHead-root {
  background-color: lightgray;
}
input#outlined-basic {
  background-color: #fff;
  border-radius: 6px;
}

/* Modal */
.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
}

.modalContainer {
  max-width: 600px;
  width: 100%;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  background-color: #ffffff;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
  border-radius: 8px;
}

.modalRight {
  width: 100%;
}

.closeBtn {
  position: fixed;
  top: 8px;
  right: 8px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 3rem;
  padding: 1rem 2rem;
  font-family: "Source Sans Pro", sans-serif;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}

[data-popup='tooltip'].popup-overlay {
  background: transparent;
}

.modal {
  font-size: 12px;
  background-color: #ffffff;
  width: 100%;
  padding: 10px;

}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 3px;
  
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}

.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -30px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

.buttons {
  width: 200px;
  margin: 0 auto;
}

.action_btn {
  width: 200px;
  margin: 0 50px;
  border: 1px solid #cfcece;
}

.darkTheme{
  background: "black";
  color: "white";
}

table {
  border-collapse: collapse;
  width: 100%;
}

thead, td {
  text-align: center;
  padding: 8px;
  border: 4px solid #ddd;
}

thead {
  font-weight: bold !important;
}

