.navbar{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 1rem;
}

.dir-pane{
    overflow-y: auto;
}

.dir-heading{
    display: flex;
    padding: 1rem;
    justify-content: center;
    background-color: #1e1e1e;
    color: white;
}

.dir-struct{
    display: flex;
    flex-direction: column;
    padding: 20px;
    overflow-y: auto;
}

.MuiTreeItem-label{
    font-size: 15px !important;
    font-weight: 400 !important;
}

.MuiTreeItem-iconContainer svg {
    font-size: 30px !important;
}

