.selectWrapper{
    padding: 5px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 20px;
    min-width: fit-content;
    overflow:hidden;
    border:2px solid #000000;
  }
  
#ColsList{
    background-color: transparent;
    border-radius: 5px;
    border:0px;
    outline:none;
  }